import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/colebemis.com/colebemis.com/src/templates/note-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Step 0`}</h2>
    <h2>{`Step 1`}</h2>
    <h2><input type="checkbox" />{` Step 2: Customize macOS preferences`}</h2>
    <p>{`These settings can be configured programicatally useing the `}<inlineCode parentName="p">{`defaults`}</inlineCode>{` shell command. however, that way is not well documented by apple. i found using the system preferences GUI to be more straightfoward and reliable.`}</p>
    <h3><input type="checkbox" />{` Sign in to iCloud`}</h3>
    <h3><input type="checkbox" />{` Add Touch ID fingerprint`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Touch ID > Add a fingerprint`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90947010-4c94eb80-e3e7-11ea-8d86-08172ca3ff52.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Enable tap to click`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Trackpad > Point & Click > [x] Tap to click`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90945814-1c941b00-e3dc-11ea-97a3-f8a745ccf84e.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Enable three finger drag`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Accessibility > Pointer Control > Trackpad Options... > [x] Enable dragging: three finger drag`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2F0eRHHef7vg.png?alt=media&token=e6247cde-10e7-402d-8647-07795497b8fc",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Customize Dock`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Dock > [x] Magnification: ~0.8`}</inlineCode></p>
    <p><inlineCode parentName="p">{`System Preferences > Dock > [x] Automatically hide and show the Dock`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90946087-6bdb4b00-e3de-11ea-9c96-b348b18bf1ac.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Enable Night Shift`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Displays > Night Shift > Schedule: Sunset to Sunrise`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90946174-62061780-e3df-11ea-8a6f-9003f3f766ef.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Change display resolution`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Displays > Display > Resolution: Scaled > Looks like 1440x900 (one left of default)`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FNiAbPXVVfT.png?alt=media&token=e367d1c4-6c78-4c90-b81b-29d4fd18f287",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Enable zoom`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Accessibility > Zoom > [x] Use scroll gesture with modifier keys to zoom: Control`}</inlineCode></p>
    <p><inlineCode parentName="p">{`System Preferences > Accessibility > Zoom > Zoom style: Full screen`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FWOw1rTkoKA.png?alt=media&token=7dc0bfda-82ce-47b0-afd2-c1efd13af564",
        "alt": null
      }}></img></p>
    <p><inlineCode parentName="p">{`System Preferences > Accessibility > Zoom > Advanced... > [ ] Smooth images`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FEM2hZ-SYje.png?alt=media&token=fc8f29b8-299d-475f-978d-cc3c2235129b",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Always show expanded control strip`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Keyboard > Touch bar shows: Expanded Control Strip`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FpiVOHQk-c1.png?alt=media&token=2a989a46-1c49-4ce3-958b-755283bcd5ad",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Customize control strip`}</h3>
    <p><inlineCode parentName="p">{`System preferences > Keyboard > Customize Control Strip...`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Brightness, Keyboard Brightness, Media, Volume, Do Not Disturb, Screen Lock`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FbzyVA8NrSh.png?alt=media&token=f20b69e7-a305-4278-acdf-562227d02dc6",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Remap caps lock key to control`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Keyboard > Modifier Keys > Caps Lock Key: Control`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FEMnWcSwRPo.png?alt=media&token=b7a9ced9-8a0d-4a6c-b6eb-4e17ff9e4594",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Add personal and work Google accounts`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Internet Accounts > + > Google`}</inlineCode></p>
    <p>{`Enable Mail, Contacts, Calendars`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90946604-3a18b300-e3e3-11ea-9ecd-0e33d8fe9e2f.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Diabled iCloud Photos, Mail, and Calendar`}</h3>
    <h3><input type="checkbox" />{` Show volume icon in menu bar`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Sound > Input > [x] Show volume in menu bar`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2F-dnC0usooG.png?alt=media&token=a3503c08-d962-4761-9597-e3817e2ac2e2",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Show bluetooth icon in menu bar`}</h3>
    <p><inlineCode parentName="p">{`System Preferences > Bluetooth > [x] Show volume in menu bar`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fcolebemis%2FsTCfvQZlqs.png?alt=media&token=1c2ce818-75f7-45f2-b74e-1dedba2858a4",
        "alt": null
      }}></img></p>
    <h3><input type="checkbox" />{` Show Finder path bar`}</h3>
    <p><inlineCode parentName="p">{`Finder > View > Show Path Bar`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90994450-9b6c8d80-e56d-11ea-8a27-0f09796a556b.png",
        "alt": "image"
      }}></img></p>
    <h3><input type="checkbox" />{` Add home directory to Finder sidebar`}</h3>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/90946830-a7c5de80-e3e5-11ea-9428-a3168f5d71a4.png",
        "alt": "image"
      }}></img></p>
    <h2><input type="checkbox" />{` Step 3: Set up GitHub SSH keys`}</h2>
    <p><a parentName="p" {...{
        "href": "https://docs.github.com/en/github/authenticating-to-github/adding-a-new-ssh-key-to-your-github-account"
      }}>{`Adding a new SSH key to your GitHub account`}</a></p>
    <h2>{`Step 4: Clone dotfiles repo`}</h2>
    <pre><code parentName="pre" {...{}}>{`git clone --recursive git@github.com:colebemis/dotfiles.git ~/.dotfiles
`}</code></pre>
    <h2>{`Step 5: Install oh my zsh`}</h2>
    <p>{`This should be part of the `}<inlineCode parentName="p">{`bootstrap`}</inlineCode>{` script but it causes the script to exit early, so for now it's a separate step.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sh -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)"
`}</code></pre>
    <h2>{`Step 5: Run bootstrap script`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`~/.dotfiles/bootstrap.sh
`}</code></pre>
    <h2>{`Step 6: Install app-store-only apps`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`boostrap`}</inlineCode>{` script installs a lot of apps using Homebrew Cask`}</p>
    <h2>{`Step 8: Customize dock`}</h2>
    <h2>{`Step 9: Sign in to apps`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      